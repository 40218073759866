import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden, IconButton } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import SessioinInfoIcon from '../../icons/SessioinInfoIcon';
import Countdown from 'react-countdown';
import axios from 'axios';
import ToggleSessionButton from '../../custom/ToggleSessionButton/ToggleSessionButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#22242A',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

interface MnnuBarProps {
  sessionDetails?: any;
}

export default function MenuBar({ sessionDetails }: MnnuBarProps) {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const history = useHistory();
  // var timeStamp = Date.now() + 1800000;
  const [timeStamp, setTimeStamp] = useState(Date.now() + 1800000);

  if (sessionDetails?.end) {
    const isoString = sessionDetails?.end?.dateTime;

    const date = new Date(isoString);

    // timeStamp = date.getTime();
    setTimeStamp(date.getTime());
  }

  const handleComplete = () => {
    room!.disconnect();
    axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/room/complete`, {
        room_sid: room?.sid,
      })
      .then(res => {
        history.push(`/end/${room?.name}?sess=${sessionDetails?._id}`);
      })
      .catch(Err => {
        console.log('Error on Disconnecting from room', Err);
      });
  };

  const checkLength = (val: any) => {
    return val.toString().length == 1 ? '0' + val : val;
  };
  const renderer = ({ hours = 0, minutes = 0, seconds = 0, completed = false }) => {
    return (
      <span style={minutes < 5 ? { color: '#FF0000' } : { color: '#fff' }}>
        {checkLength(hours) + ':' + checkLength(minutes) + ':' + checkLength(seconds)}
      </span>
    );
  };

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                color: '#fff',
              }}
            >
              <Typography style={{ color: '#fff' }} variant="body1">
                {`Room expires in - `}
              </Typography>
              <Countdown
                date={timeStamp}
                autoStart={true}
                zeroPadTime={2}
                zeroPadDays={0}
                onComplete={handleComplete}
                renderer={renderer}
              />
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center" style={{ gap: '18px' }}>
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
              <Hidden smDown>
                <Menu sessionDetails={sessionDetails} />
              </Hidden>
              <Hidden mdUp>
                <Grid style={{ flex: 1 }}>
                  <Grid container justifyContent="flex-end" style={{ gap: '18px' }}>
                    {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
                    {sessionDetails._id && <ToggleSessionButton />}
                  </Grid>
                </Grid>
              </Hidden>
              <EndCallButton sessionDetails={sessionDetails} />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end" style={{ gap: '18px' }}>
                {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
                {sessionDetails._id && <ToggleSessionButton />}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
